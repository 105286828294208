import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import AboveFooterSection from '../../components/AboveFooterSection'

import Layout from '../../components/Layout'
import HeaderSEO from '../../components/partials/HeaderSEO'
import Spacing from '../../components/partials/Spacing'
import { StaticImage } from 'gatsby-plugin-image'
import ModalImage from 'react-modal-image'
import nuvo_architecture from '../../resources/images/clients/nuvo/nuvo-architecture.jpg'
const nuvo = () => {
  const data = useStaticQuery(graphql`
    query NuvoSEO {
      allContentfulPages(
        filter: { pageReference: { pageIdentifier: { eq: "NuvoCaseStudy" } } }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node

  return (
    <Layout newsletter={false}>
      <HeaderSEO pageSEO={pageSEO} />
      <section>
        <div className="container-1 hero-margin">
          <div className="d-flex flex-wrap justify-content-center mobile-padding-top-half">
            <div className="col-lg-9 col-md-12 col-12 blog-content-parent ">
              <div className="text-center">
                <StaticImage
                  className="w-20"
                  src="../../resources/images/clients/nuvo/nuvo-green.svg"
                  quality={100}
                  alt="Nuvo"
                  placeholder="tracedSVG"
                />
              </div>
              <div className="w-75 mx-auto mt-4 mb-3 mobile-w-100">
                <h1 className="text-center my-2 lh-2-3">
                  How FDA-cleared INVU by Nuvo™ safely processes real-time heart
                  rate data with Apache Kafka
                </h1>
              </div>
              <p className="f-20 text-center px-5 mobile-padding-reset">
                This hyper-growth Medtech company is revolutionizing pregnancy
                care, but this means carefully addressing how engineers access
                and use streaming data to comply with regulations.
              </p>
              <div className="d-flex justify-content-center mb-3 mt-4">
                <StaticImage
                  src="../../resources/images/clients/nuvo/hero-image.jpg"
                  quality={100}
                  placeholder="tracedSVG"
                  alt="Nuvo hero"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-cyan mobile-margin-top mt-5">
        <div className="container-1 py-5">
          <div className="d-flex flex-wrap justify-content-around">
            <div className="col-sm col-12 px-4 text-center mobile-margin-top">
              <h2 className="f-32 fw-900">FDA-cleared</h2>
              <p>through careful data access controls</p>
            </div>
            <div className="col-sm col-12 px-4 text-center">
              <h2 className="f-32 fw-900">18k ECG & PCG packets</h2>
              <p>per min per patient of biometric data processed by Kafka</p>
            </div>
            <div className="col-sm col-12 px-4 text-center mobile-margin-top">
              <h2 className="f-32 fw-900">Dozens of data consumers</h2>
              <p>across the organization</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-1 mobile-margin-top mt-5">
          <div className="d-flex flex-wrap justify-content-center ">
            <div className="col-sm-9 col-12">
              <h3 className="text-center mt-4 mb-2 mobile-margin-top">
                Nuvo is pioneering the future of connected healthcare.
              </h3>
              <p>
                <a
                  href="https://www.nuvocares.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Nuvo
                </a>
                ’s data products roll out at an impressive rate for a heavily
                regulated organization - from wearable bands collecting
                biosensory data and web services for health practitioners, to
                AI-powered insights on foetal heart rate.
              </p>
              <p>
                Several years ago Nuvo totally rebuilt and modernized their data
                infrastructure to suit streaming data and applications. High
                availability, resiliency and real-time processing were all
                must-haves to reliably capture and transform 18,000 packets of
                electro and phonocardiogram per minute as well as other
                biometric data.
              </p>
              <p>
                As they assessed the best technology stack, <b>RabbitMQ</b> did
                not give Nuvo the flexibility they needed. Apache Kafka and
                Kafka Connect with Kubernetes - plus MQTT for the Internet of
                Things - covers their requirements allowing engineering teams to
                write in whatever framework they like from python to C-Code and
                Java.
              </p>
              <ModalImage
                className="mt-4 mb-3"
                small={nuvo_architecture}
                large={nuvo_architecture}
                alt="Nuvo Architecture"
              />
              <h3 className="text-center mt-4 mb-2 mobile-margin-top">
                “Data access keeps me up at night!”
              </h3>
              <p>
                Amit Hasson, VP Software Engineering at Nuvo, describes the data
                protection and regulatory minefield they are working with:
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-usecase-nuvo py-5 mobile-margin-top mt-4">
        <div className="container-1 py-5">
          <div className="d-flex flex-wrap justify-content-center">
            <div className="col-sm-8 col-12">
              <p className="f-20 text-white text-center mb-0 ">
                “Our biggest concerns are around data leakage and compliance for
                regulations such as HIPAA. Any kind of data breach or security
                incident can bring the whole business down. Certain teams are
                unable to have access to personal data for compliance reasons -
                so this is key in our due diligence process for any technologies
                we work with.”
              </p>

              <div className="mt-4 text-center">
                <div className="d-flex flex-wrap align-items-center justify-content-center">
                  <StaticImage
                    src="../../resources/images/clients/nuvo/amit.png"
                    quality={100}
                    alt="Amit Hasson"
                    placeholder="tracedSVG"
                  />
                  <p className="text-white fw-700 pl-3 mt-2 mobile-padding-reset">
                    Amit Hasson, VP Software Engineering - Nuvo
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-1 mt-5">
          <div className="d-flex flex-wrap justify-content-center">
            <div className="col-sm-9 col-12">
              <p>
                The regulatory requirements do not end with HIPAA. FDA approval
                has taken years of careful box-checking and process-building.
                This means having clear best practices around who accesses what;
                a primary port of call for Nuvo’s Security Officer.
              </p>
              <p>
                And then, there is the fact that Nuvo cannot have “Software of
                unknown pedigree” in their systems without sufficient technical
                and legal due diligence. As a result, Nuvo’s engineering teams
                have an open-source, build-first philosophy when it comes to
                their data projects.
              </p>
              <p>
                If there is a strong commercial product or service, they will
                make a case for it, and there are only two: Auth0 and Lenses.
              </p>
              <StaticImage
                src="../../resources/images/clients/nuvo/nuvo-product.png"
                quality={100}
                placeholder="tracedSVG"
                alt="Nuvo Product"
              />
              <h3 className="text-center my-3">
                Troubleshooting & debugging data in Kafka using Lenses
              </h3>
              <p>
                Nuvo selected Lenses to provide a developer experience over
                Apache Kafka.
              </p>
              <p>
                This meant that the back-end team could easily troubleshoot and
                debug data using a secure interface, without needing to turn to
                the command line.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-usecase-nuvo py-5 mobile-margin-top mt-4">
        <div className="container-1 py-5">
          <div className="d-flex flex-wrap justify-content-center">
            <div className="col-sm-8 col-12">
              <p className="f-20 text-white text-center mb-0 ">
                “Having Lenses as a developer experience and UI for Kafka made
                sense for us. We use it locally or at a specific point in time
                to identify issues that could impact our services - a serious
                matter of attention for us in making our platform and
                applications healthy and fault tolerant.”
              </p>

              <div className="mt-4 text-center">
                <div className="d-flex flex-wrap align-items-center justify-content-center">
                  <StaticImage
                    src="../../resources/images/clients/nuvo/amit.png"
                    quality={100}
                    alt="Amit Hasson"
                    placeholder="tracedSVG"
                  />
                  <p className="text-white fw-700 pl-3 mt-2 mobile-padding-reset">
                    Amit Hasson, VP Software Engineering - Nuvo
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Spacing />

      <div className="mobile-margin-top mobile-margin-bottom">
        <div className="container-1">
          <div className="d-flex flex-wrap justify-content-center">
            <div className="col-9">
              <ul className="red-checked-list pl-0">
                <li>
                  <span>
                    <p>
                      <b>Troubleshooting & debugging:</b> Lenses offers an
                      easy-to-use interface over Kafka
                    </p>
                  </span>
                </li>
                <li>
                  <span>
                    <p>
                      <b>Kafka-agnostic with no lock-in:</b> Acts as a layer
                      over your existing Kafka - managed, on-prem or
                      self-managed
                    </p>
                  </span>
                </li>
                <li>
                  <span>
                    <p>
                      <b> Compliant by design:</b> Data policies that allow
                      granular, flexible access controls for Kafka and its
                      related data technologies.
                    </p>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <AboveFooterSection
        text={
          'Want to accelerate time-to-market of healthcare applications on Kafka?'
        }
        ctaText={"Let's go"}
        ctaLink="/start/"
      />
    </Layout>
  )
}

export default nuvo
